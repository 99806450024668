import { json } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import { useLoaderData } from "@remix-run/react";
import {
  LANGUAGE,
  BRAND_ID,
  STORE_ID,
  API_VERSION,
  BASE_URL,
  FETCH_DATA,
  VALIDATE_ZIPCODE,
  DEVICE_DATA,
} from "./config/constants";
import Maintenance from "./components/Maintenance";
import { CartProvider } from "./context/CartContext";
import {
  getUserFromSession,
  setBusinessSelection,
} from "./utils/session.server";
import BusinessSelectionDialog from "./components/shared/BusinessSelectionDialog";
import { BusinessProvider, useBusiness } from "./context/BusinessContext";

import "./tailwind.css";
import MobileNavigation from "./components/shared/MobileNavigation";
import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "./components/shared/LoadingIndicator";
import * as Sentry from "@sentry/remix";
import Footer from "./components/shared/Footer";
import Header from "./components/shared/Header";
import Error from "./components/shared/Error";
import ErrorCatchAll from "./components/shared/ErrorCatchAll";

export const loader = async ({ request }) => {
  const url = new URL(request.url);
  const business = url.searchParams.get("business");

  if (business) {
    const response = await setBusinessSelection(request, business);
    return redirect(url.pathname, {
      headers: response.headers, // Set session cookie with business
    });
  }

  const user = await getUserFromSession(request);
  const pathname = url.pathname;

  // // Log all user data as additional context
  // Sentry.setContext("user_data", user);

  // // Capture the custom error with the complete user data in context
  // Sentry.captureException(
  //   new Error(
  //     `Custom error: Full user data logged for user ${user.uid} on ${
  //       new URL(request.url).pathname
  //     }`
  //   )
  // );

  if (
    user &&
    user.is_logged_in &&
    (!user.first_name || !user.email) &&
    !pathname.includes("account-update")
  ) {
    return redirect("/account-update");
  }

  let terminology;
  if (user.business === "nationwide") {
    terminology = {
      announcement:
        "Orders placed after 4 PM will be processed the next business day.",
      business: {
        selection:
          "Select our convenient local grocery delivery or enjoy nationwide shipping of premium meats, fish, and cheeses.",
        store_switch_notice: "Switching stores will empty your cart.",
        local_delivery_zone_notice:
          "* Our local delivery service is available for select zip codes within New York and New Jersey.",
      },
      cart: {
        shipping: "Shipping",
        no_shipping_cost: "FREE",
      },
    };
  } else {
    terminology = {
      announcement: "Same-day delivery cutoff time is 4 PM",
      business: {
        selection:
          "Select our convenient local grocery delivery or enjoy nationwide shipping of premium meats, fish, and cheeses.",
        store_switch_notice: "Switching stores will empty your cart.",
        local_delivery_zone_notice:
          "* Our local delivery service is available for select zip codes within New York and New Jersey.",
      },
      cart: {
        shipping: "Delivery Fee",
        no_shipping_cost: "-",
      },
    };
  }

  const googleMapsAPIKEY = process.env.GOOGLE_MAPS_API_KEY;

  let query = url.searchParams.get("query");
  let pageNo = url.searchParams.get("pageNo") || 1;
  const limit = url.searchParams.get("limit") || 60;

  const categoryUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/inventory/getCategories?lang=${LANGUAGE}`;
  const brandUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/marketplace/homescreen/version?${DEVICE_DATA}&lang=${LANGUAGE}`;
  const brandClosedUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/marketplace/homescreen/brand_status`;

  const [collections, brand, brandClosed] = await Promise.all([
    FETCH_DATA(categoryUrl, "data"),
    FETCH_DATA(brandUrl, "brand"),
    FETCH_DATA(brandClosedUrl, "data"),
  ]);
  if (brandClosed.success && brandClosed.data) {
    brand.status = brandClosed.data.status;
  }

  return json({
    collections:
      collections.success && collections.data && collections.data.length > 0
        ? [...collections.data]
        : [],
    brand,
    user,
    search: {
      query,
      pageNo,
      limit,
    },
    googleMapsAPIKEY,
    terminology,
  });
};

export const action = async ({ request }) => {
  const url = new URL(request.url);
  const formData = await request.formData();
  const zipcode = formData.get("zipcode");
  const formId = formData.get("formId");
  const business = formData.get("business");

  if (formId === "zipcodeForm") {
    if (!zipcode) {
      return json({
        zipcodeResponse: {
          error: true,
          message: "There was an error, please choose another option.",
        },
      });
    }

    const isZipcodeValid = VALIDATE_ZIPCODE(zipcode);
    if (!isZipcodeValid) {
      return json({
        zipcodeResponse: {
          error: true,
          message: "Please enter a valid zipcode.",
        },
      });
    }

    const numberOfPackages = 1;
    const transitData = { zipcode, numberOfPackages };

    // Fetch transit data
    const transitResponse = await fetch(`${url.origin}/api/checkout/transit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transitData),
    });

    const transitResponseData = await transitResponse.json();

    if (transitResponseData.error) {
      return json({
        zipcodeResponse: {
          error: true,
          message: "There was an error, please choose another option.",
        },
      });
    }

    const { businessTransitDays, deliveryDate } = transitResponseData;

    const zipcodeMeta = { zipcode };
    const zipcodeUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/delivery_zones/validateZipcode`;
    const zipcodeValidation = await FETCH_DATA(
      zipcodeUrl,
      "data",
      "POST",
      zipcodeMeta,
      "application/x-www-form-urlencoded"
    );
    // if (!zipcodeValidation.success) {
    //   await setBusinessSelection(request, "local");
    // } else {
    //   await setBusinessSelection(request, "nationwide");
    // }
    // if (businessTransitDays > DELIVERY_TRESHOLD) {
    //   await setBusinessSelection(request, "nationwide");
    // } else {
    //   await setBusinessSelection(request, "local");
    // }

    return json({
      zipcodeResponse: {
        success: true,
        data: {
          business_transit_days: businessTransitDays,
          delivery_date: deliveryDate,
          business: zipcodeValidation.success ? "local" : "nationwide",
        },
      },
    });
  }

  if (formId === "businessTypeForm") {
    try {
      const { headers } = await setBusinessSelection(request, business);

      return redirect(`/?business=${business}`, {
        headers: headers, // Ensure you're sending back the Set-Cookie header
      });
    } catch (error) {
      return json({ error: "Invalid form submission" }, { status: 400 });
    }
  }

  return json({ error: "Invalid form submission" }, { status: 400 });
};

export const links = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export function Layout({ children }) {
  const location = useLocation();
  const grayBackgroundRoutes = [
    "/account",
    "/account/",
    "/orders",
    "/orders/",
    /^\/thank-you\/\d+$/,
    /^\/order\/\d+$/,
  ];
  const isGrayBackground = grayBackgroundRoutes.some((route) =>
    typeof route === "string"
      ? route === location.pathname
      : route.test(location.pathname)
  );
  return (
    <CartProvider>
      <BusinessProvider>
        <html lang="en" className="bg-white">
          <head>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <script src="https://tokenization.banquestgateway.com/tokenization/v0.2"></script>
            <script
              src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB1meGExCFakYM_b63qRMiMmEe_WuN_Hak&libraries=places`}
              async
            ></script>

            <Meta />
            <Links />
          </head>
          <body
            className={
              isGrayBackground
                ? "bg-gray-100 text-black"
                : "bg-white text-black"
            }
          >
            {children}
            <ScrollRestoration />
            <Scripts />
          </body>
        </html>
      </BusinessProvider>
    </CartProvider>
  );
}

export function App() {
  const { brand, user, collections, search, terminology } = useLoaderData();
  const { isBusinessModalOpen, setIsBusinessModalOpen } = useBusiness();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      if (
        user &&
        user.is_logged_in &&
        (!user.first_name || !user.email) &&
        !location.pathname.includes("account-update")
      ) {
        navigate("/account-update");
      }
    };

    checkUser();
  }, [location.pathname, navigate]);

  // Handle error state
  if (brand.error) {
    return <p>Error loading brand: {brand.message}</p>;
  }

  // Handle empty data state
  if (brand.loaded && brand.success && !brand.data) {
    return <p>No brand settings available.</p>;
  }

  if (brand?.status != "1") {
    return <Maintenance />;
  }

  return (
    <div className="pb-[102px] lg:pb-0">
      <div className="text-[14px] leading-[16px] flex justify-center items-center bg-primary text-black py-3 px-4 text-center">
        {terminology.announcement}
      </div>
      <Outlet context={{ brand, user, collections, search, terminology }} />
      <BusinessSelectionDialog
        brand={brand}
        terminology={terminology}
        /* isOpen={user?.business === null || isBusinessModalOpen} */
        isOpen={isBusinessModalOpen}
        setIsOpen={setIsBusinessModalOpen}
        selectedBusiness={user?.business}
      />
      <MobileNavigation />
    </div>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  // Check if the error status is 404
  if (error.status === 404) {
    return <Error />;
  }

  // Fallback for other errors
  return <ErrorCatchAll error={error} />;
}

export default function Root() {
  return <App />;
}
